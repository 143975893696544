import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Image = ({ imgName }) => {
  /* eslint-disable-next-line */
  const data = useStaticQuery(image)
  const result = data.allImageSharp.edges.find(
    edge => edge.node.fluid.originalName === imgName
  )

  if (!result) {
    return null
  }
  return <Img fluid={result.node.fluid} />
}

export const image = graphql`
  query {
    allImageSharp {
      edges {
        node {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
            originalName
          }
        }
      }
    }
  }
`

Image.propTypes = {
  imgName: PropTypes.string.isRequired
}

export default Image
