import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { lighten } from 'polished'
import dayjs from 'dayjs'
import allExchanges from './exchanges-list'
import { Container, Row, Column } from '../ui/layout/Grid'
import { ContentBox } from '../ui/layout/ContentBox'
import Image from '../ui/Image'
import { Input } from '../ui/forms/Input'
import Button from '../ui/forms/Buttons'
import linkoutIcon from '../../images/assets/icons/linkout.svg'

const ExchangeBox = styled(ContentBox)`
  padding: 2em;
  z-index: initial;

  h3 {
    border-top: 1px solid ${ props => props.theme.color.grey[1] };
    font-size: 2.2rem;
    margin: 0;
    margin-top: 1em;
    padding-top: 0.7em;
  }

  time {
    color: ${ props => props.theme.color.grey[3] };
    font-weight: 700;
  }

  h3, time {
    transition: color 0.25s ease;
  }

  small {
    color: ${ props => props.theme.color.primary[2] };
    font-size: 1.5rem;
    position: absolute;
    bottom: -1.5em;
    left: 0;
    opacity: 0;
    transform: scaleY(0.8);
    transition: all 0.25s ease;
  }

  a {
    position: relative;
    display: block;

    :hover {
      h3, time {
        color: ${ props => props.theme.color.primaryColor };
      }

      small {
        opacity: 1;
        transform: scaleY(1);
      }

      &::after {
        opacity: 1;
        transform: translateX(0);
      }
    }

    ::after {
      content: '';
      background-image: url(${ linkoutIcon });
      background-size: 100%;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      right: 0;
      opacity: 0;
      transform: translateX(0.5em);
      transition: all 0.25s ease;
    }
  }
`

const InactiveStyle = styled.div`
  background-color: ${ props => !props.active ? props.theme.color.primary[6] : props.theme.color.grey[3] };
  padding: 0 0.5em;
  border-radius: 20px;
  color: ${ props => !props.active ? '#fff' : props.theme.color.primary[6] };
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  font-size: 1.4rem;
  cursor: pointer;
  transition: background-color, color 0.25s ease;
`

const Popup = styled.div`
  background-color: ${ props => props.theme.color.primary[6] };
  position: absolute;
  bottom: calc(100% + 0.8em);
  left: 50%;
  color: #fff;
  font-size: 1.4rem;
  border-radius: 6px;
  width: 240px;
  z-index: 1000;
  padding: 0.3em 0.8em;
  visibility: ${ props => props.active ? 'visible' : 'hidden' };
  opacity: ${ props => props.active ? 1 : 0 };
  transform: ${ props => props.active ? 'translate(-50%, 0) ' : 'translate(-50%, -0.5em)' };
  transition: all 0.15s ease;

  &::after {
    content: '';
    width: 12px;
    height: 12px;
    position: absolute;
    left: 50%;
    bottom: -8px;
    transform: rotate(45deg) translateX(-50%);
    background-color: ${ props => props.theme.color.primary[6] };
  }
`

const Inactive = () => {
  const [popup, setPopup] = useState(false)

  return (
    <InactiveStyle
      active={popup}
      onClick={() => setPopup(!popup)}
    >
      <Popup active={popup}>This exchange kicked the proverbial bucket. However, we have retained historical data.</Popup>
      <span>Inactive</span>
    </InactiveStyle>
  )
}

const Exchanges = () => {
  const [exchangeList, setExchangeList] = useState([])
  const [showAll, setShowAll] = useState(false)
  useEffect(() =>
    showAll
      ? setExchangeList(allExchanges)
      : setExchangeList(allExchanges.slice(0, 6)),
  [showAll])

  const filterEx = e => {
    const term = e.target.value.toLowerCase()
    return setExchangeList(
      allExchanges.filter(item => {
        const name = item.name.toLocaleLowerCase()
        return name.includes(term)
      })
    )
  }

  return (
    <Container>
      {showAll &&
        <Row
          mt="0"
          mb="0"
        >
          <Column css={'padding: 1em'}>
            <Input
              type="text"
              placeholder="Filter supported exchanges..."
              onChange={e => filterEx(e)}
              autoFocus
            />
          </Column>
        </Row>
      }
      <Row
        grid={1 / 3}
        gutter={1}
        mt="0"
        mb="1em"
      >
        {exchangeList.map(exchange =>
          <Column key={exchange.name}>
            <ExchangeBox>
              {exchange.inactive && <Inactive />}
              <Image imgName={exchange.logo} />
              {exchange.url &&
                <a
                  href={exchange.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h3>{exchange.name}</h3>
                  <time>{dayjs(exchange.added).format('MMMM DD, YYYY')}</time>
                  <small>(Data collection start date)</small>
                </a>
              }
            </ExchangeBox>
          </Column>
        )}
        {exchangeList.length <= 0 && <p>No data</p>}
      </Row>
      {!showAll &&
        <Row mt="0">
          <Column align="center">
            <Button
              as="button"
              appearance="primary"
              css={'font-size: 1.6rem'}
              onClick={() => setShowAll(true)}
            >
              Show All
              ({allExchanges.length - 6}+)
            </Button>
          </Column>
        </Row>
      }
    </Container>
  )
}

export default Exchanges
