export default [
  {
    name: 'BCEX Global',
    added: '2018-07-25',
    logo: 'bcex.png',
    url: 'https://bcex.ca'
  },
  {
    name: 'Bibox',
    added: '2018-08-21',
    logo: 'bibox.png',
    url: 'https://bibox.com'
  },
  {
    name: 'Binance',
    added: '2017-10-17',
    logo: 'binance.png',
    url: 'https://www.binance.com'
  },
  {
    name: 'Bitbank',
    added: '2018-08-24',
    logo: 'bitbank.png',
    url: 'https://www.bitbank.com'
  },
  {
    name: 'Bitfinex',
    added: '2013-04-01',
    logo: 'bitfinex.png',
    url: 'https://www.bitfinex.com'
  },
  {
    name: 'bitFlyer',
    added: '2015-06-24',
    logo: 'bitflyer.png',
    url: 'https://www.bitflyer.com'
  },
  {
    name: 'Bithumb',
    added: '2017-08-26',
    logo: 'bithumb.png',
    url: 'https://www.bithumb.com'
  },
  {
    name: 'Bitmex',
    added: '2017-01-01',
    logo: 'bitmex.png',
    url: 'https://www.bitmex.com'
  },
  {
    name: 'Bitstamp',
    added: '2011-08-18',
    logo: 'bitstamp.png',
    url: 'https://www.bitstamp.net'
  },
  {
    name: 'Bitrrex',
    added: '2016-05-30',
    logo: 'bittrex.png',
    url: 'https://www.bittrex.com'
  },
  {
    name: 'Bit-Z',
    added: '2018-03-23',
    logo: 'bit-z.png',
    url: 'https://www.bit-z.com/?lang=en'
  },
  {
    name: 'BTCBOX',
    added: '2016-05-30',
    logo: 'btcbox.png',
    url: 'https://www.btcbox.co.jp'
  },
  {
    name: 'CEX.io',
    added: '2018-03-07',
    logo: 'cex.io.png',
    url: 'https://www.cex.io'
  },
  {
    name: 'Coinbase',
    added: '2014-12-01',
    logo: 'coinbase.png',
    url: 'https://www.coinbase.com'
  },
  {
    name: 'Coinbene',
    added: '2018-05-10',
    logo: 'coinbene.png',
    url: 'https://www.coinbene.com'
  },
  {
    name: 'Coinegg',
    added: '2018-03-02',
    logo: 'coinegg.png',
    url: 'https://www.coinegg.com'
  },
  {
    name: 'Coinone',
    added: '2018-03-23',
    logo: 'coinone.png',
    url: 'https://www.coinone.co.kr'
  },
  {
    name: 'Coinsbank',
    added: '2018-08-09',
    logo: 'coinsbank.png',
    url: 'https://www.coinsbank.com'
  },
  {
    name: 'Ethfinex',
    added: '2018-06-30',
    logo: 'ethfinex.png',
    url: 'https://www.ethfinex.com'
  },
  {
    name: 'Exmo',
    added: '2018-03-23',
    logo: 'exmo.png',
    url: 'https://www.exmo.com'
  },
  {
    name: 'Exx',
    added: '2018-07-15',
    logo: 'exx.png',
    url: 'https://www.exx.com'
  },
  {
    name: 'Fisco',
    added: '2018-03-22',
    inactive: true,
    logo: 'fisco.png',
    url: 'https://www.fisco.co.jp/en/'
  },
  {
    name: 'Gatecoin',
    added: '2016-02-17',
    inactive: true,
    logo: 'gatecoin.png',
    url: 'https://www.gatecoin.com'
  },
  {
    name: 'Gate.io',
    added: '2018-05-04',
    logo: 'gate.io.png',
    url: 'https://www.gate.io'
  },
  {
    name: 'Gemini',
    added: '2015-10-08',
    logo: 'gemini.png',
    url: 'https://www.gemini.com'
  },
  {
    name: 'Gopax',
    added: '2018-03-23',
    logo: 'gopax.png',
    url: 'https://www.gopax.com'
  },
  {
    name: 'HitBTC',
    added: '2016-09-24',
    logo: 'hitbtc.png',
    url: 'https://www.hitbtc.com'
  },
  {
    name: 'Huobi',
    added: '2018-04-05',
    logo: 'huobi.png',
    url: 'https://www.huobi.com'
  },
  {
    name: 'itBit',
    added: '2015-10-01',
    logo: 'itbit.png',
    url: 'https://www.itbit.com'
  },
  {
    name: 'Korbit',
    added: '2018-03-23',
    logo: 'korbit.png',
    url: 'https://www.korbit.co.kr'
  },
  {
    name: 'Kraken',
    added: '2013-09-10',
    logo: 'kraken.png',
    url: 'https://www.kraken.com'
  },
  {
    name: 'Kucoin',
    added: '2018-02-06',
    logo: 'kucoin.png',
    url: 'https://www.kucoin.com'
  },
  {
    name: 'LBank',
    added: '2018-08-24',
    logo: 'lbank.png',
    url: 'https://www.lbank.info'
  },
  {
    name: 'Liqui',
    added: '2018-04-02',
    inactive: true,
    logo: 'liqui.png',
    url: 'https://liqui.io'
  },
  {
    name: 'Livecoin',
    added: '2018-08-24',
    logo: 'livecoin.png',
    url: 'https://livecoin.net/en'
  },
  {
    name: 'OkCoin',
    added: '2013-06-12',
    logo: 'okcoin.png',
    url: 'https://www.okcoin.com'
  },
  {
    name: 'Okex',
    added: '2018-03-30',
    logo: 'okex.png',
    url: 'https://www.okex.com/lang/en-US'
  },
  {
    name: 'Poloniex',
    added: '2014-01-18',
    logo: 'poloniex.png',
    url: 'https://www.poloniex.com'
  },
  {
    name: 'QuadrigaCX',
    added: '2018-08-24',
    logo: 'quadrigacx.png',
    url: 'https://quadrigacx.com'
  },
  {
    name: 'Quoine',
    added: '2015-10-28',
    logo: 'quoine.png',
    url: 'https://quoine.com'
  },
  {
    name: 'UpBit',
    added: '2018-04-13',
    logo: 'upbit.png',
    url: 'https://upbit.com'
  },
  {
    name: 'Wex',
    added: '2018-04-12',
    inactive: true,
    logo: 'wex.png',
    url: 'https://wez.nz'
  },
  {
    name: 'YoBit',
    added: '2018-02-01',
    logo: 'yobit.png',
    url: 'https://yobit.net'
  },
  {
    name: 'Zaif',
    added: '2016-05-30',
    logo: 'zaif.png',
    url: 'https://zaif.jp/?lang=en'
  },
  {
    name: 'Zb.Com',
    added: '2018-03-30',
    logo: 'zb.com.png',
    url: 'https://zb.com'
  },
]
