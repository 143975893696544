import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { rgba, timingFunctions, lighten, darken, math } from 'polished'
import { useInView } from 'react-intersection-observer'

import SEO from '../../components/seo'
import { Wrapper, Container, Row, Column } from '../../components/ui/layout/Grid'
import Bg from '../../images/products/data_sales_bg.svg'
import theme, { devices } from '../../theme/'
import { ContentBox, ContentBoxIcon } from '../../components/ui/layout/ContentBox'
import { SectionHeading } from '../../components/ui/Heading'
import { TopTitle } from '../../components/Hero/PageIntro'
import Exchanges from '../../components/Products/Exchanges'
import CTA from '../../components/Products/CTA'
import Pricing from '../../components/Products/Pricing'
import { InfoBox, Main, Intro } from '../../components/Products/Elements'
import Button from '../../components/ui/forms/Buttons'
import { DataPricing } from '../../components/Products/pricing-list'
import LinkOut from '../../components/ui/forms/LinkOut'

// Icons
import mlIcon from '../../images/products/ml_icon.svg'
import backtestIcon from '../../images/products/backtest_icon.svg'
import redundancyIcon from '../../images/products/redundancy_icon.svg'
import unifiedIcon from '../../images/products/unified_data_icon.svg'
import dataSalesIcon from '../../images/products/data_sales_icon.svg'
import { Tick, Info } from '../../components/ui/Icons'
import linkoutIcon from '../../images/assets/icons/linkout.svg'

const Line = styled.div`
  background-color: ${ props => props.theme.color.accentColor };
  width: ${ props => (props.animate ? '100%' : '0') };
  height: 3px;
  opacity: ${ props => (props.animate ? '1' : 0) };
  position: absolute;
  top: calc(50% + 19px);
  box-shadow: -4px 4px 20px ${ props => props.theme.color.primary[6] };
  transform: translateY(-50%);
  transition: width 1s ${ timingFunctions('easeOutSine') };
  transition-delay: 1s;

  @media(min-width: ${ math(theme.breakpoints.hd + '2px') }) {
    top: calc(50% + 28px);
  }

  @media(${ devices.mobile }) {
    top: calc(50% + 15px);
  }

  @media(${ devices.tablet }) {
    margin-left: -1.5em;
  }
`

const Circle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: ${ props => props.theme.color.primaryColor };
  border: 3px solid ${ props => props.theme.color.accentColor };
  opacity: ${ props => (props.animate ? 1 : 0) };
  transform: ${ props => props.animate ? 'scale(1)' : 'scale(0)' };
  transition: all 0.7s ${ timingFunctions('easeOutSine') };
  transition-delay: 2s;

  @media(${ devices.tablet }) {
    &:nth-child(3n) {
      display: none;
    }
  }
`

const ChatBubble = styled.div`
  ${ ({ options }) => options.bgColor &&
    css`
      background-image: linear-gradient(0, ${ darken(0.15, options.bgColor) }, ${ options.bgColor });
    `
  }
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  transition: opacity 0.7s ${ timingFunctions('easeOutSine') };
  transition-delay: ${ ({ options }) => options.delay && `${ options.delay }s` };
  padding: 0.9em 0;
  top: ${ ({ options }) => options.position && options.position.top };
  margin-left: ${ ({ options }) => options.position && options.position.left };
  border-radius: ${ ({ options }) => options.corner && options.corner };
  box-shadow: inset 0 1px 0 ${ rgba('#fff', 0.15) }, 0 6px 20px ${ props => rgba(props.theme.color.primary[5], 0.5) };

  ${ ({ options }) =>
    options.size === 'big' &&
    css`
      width: 240px;
      height: 120px;
    ` }

  ${ ({ options }) =>
    options.size === 'small' &&
    css`
      width: 120px;
      height: 80px;
    ` }

    span {
      background-color: ${ ({ options }) => lighten(0.2, options.bgColor) };
      display: block;
      height: 15px;
      border-radius: 20px;
      position: relative;
      left: 12%;

      &:nth-child(1) {
        width: 70%;
      }

      &:nth-child(2) {
        width: 30%;
      }

      &:nth-child(3) {
        width: 50%;
      }
  }
`

const ExtendedBox = styled(ContentBox)`
  padding: 2em;
  text-align: center;

  h2 {
    margin: 0.3em 0;
  }

  p {
    font-size: 1.7rem;
  }
`

const LiftContainer = styled.div`
  background-color: ${ props => rgba(props.theme.color.grey[0], 0.5) };
  padding: 1.2em;
  border-radius: ${ props => props.theme.radius.box };

  h4 {
    margin: 0;
    font-size: 2rem;
  }

  ul {
    list-style: none;
    padding: 0;
    list-style:none;
    display:flex;
    flex-wrap: wrap;

    li {
      display: flex;
      align-items:center;
      margin-right: 1em;

      svg {
        margin-right: 0.3em;
      }
    }
  }
`

const LinkOutBox = styled(ContentBox)`
  background-image: url(${ linkoutIcon });
  background-position: 92% center;
  background-repeat: no-repeat;
  background-size: 24px;
  transition: transform 0.25s ease;

  span {
    color: ${ props => props.theme.text.fontColor };
  }

  :hover {
    transform: translateY(-0.3em);
  }
`

const Bubble = props => {
  return (
    <ChatBubble options={props}>
      {props.size === 'big' && (
        <>
          <span />
          <span />
          <span />
        </>
      )}
      {props.size === 'small' && <span />}
    </ChatBubble>
  )
}

const DataSales = () => {
  const [ref, inView] = useInView()

  return (
    <>
      <SEO
        title="Cryptocurrency Exchange Data in One Place"
        description="By using Investabit you can easily
        download the highest quality, machine learning ready, historical market
        data from cryptocurrency markets"
      />
      <Container size="md">
        <Row>
          <Column>
            <Intro>
              <img
                src={dataSalesIcon}
                alt="Cryptocurrency exchange data"
              />
              <h1>Cryptocurrency Exchange Data in <strong>One Place</strong></h1>
              <p
                css={`
                  opacity: 0.7;
                `}
              >
                By using Investabit you can easily download the highest quality, machine learning ready, historical market data from cryptocurrency markets
              </p>
              <Button
                to="/account/register"
                appearance="primary"
              >
                Start Your Order
              </Button>
              <Button
                as="a"
                appearance="secondary"
                href="#pricing"
              >
                See Pricing
              </Button>
            </Intro>
          </Column>
        </Row>
      </Container>
      <Main
        ref={ref}
        bgImage={Bg}
        elHeight={1000}
      >
        <Line animate={inView}>
          <Container
            css={`
              position: relative;
              top: -48px;
            `}
          >
            <Row
              grid={1 / 4}
              align="center"
              justify="space-between"
            >
              <Circle animate={inView}>
                <Bubble
                  size="big"
                  corner="60px 60px 60px 0"
                  bgColor={theme.color.primary[5]}
                  position={{
                    top: '-260px'
                  }}
                />
                <Bubble
                  size="small"
                  bgColor={theme.color.primary[5]}
                  position={{
                    top: '-120px'
                  }}
                  corner="0 60px 60px 60px"
                />
              </Circle>
              <Circle animate={inView}>
                <Bubble
                  size="big"
                  bgColor="#E15C7E"
                  position={{
                    top: '80px',
                    left: '-223px'
                  }}
                  corner="60px 0 60px 60px"
                />
              </Circle>
              <Circle animate={inView}>
                <Bubble
                  size="small"
                  bgColor={theme.color.accentColor}
                  position={{
                    top: '-120px',
                    left: '-100px'
                  }}
                  corner="60px 60px 0 60px"
                />
              </Circle>
              <Circle animate={inView}>
                <Bubble
                  size="big"
                  bgColor="#FFC456"
                  position={{
                    top: '-160px',
                    left: '-223px'
                  }}
                  corner="60px 60px 0 60px"
                />
                <Bubble
                  size="small"
                  bgColor={theme.color.primary[5]}
                  position={{
                    top: '80px',
                    left: '-100px'
                  }}
                  corner="60px 0 60px 60px"
                />
              </Circle>
            </Row>
          </Container>
        </Line>
      </Main>
      <Container
        css={`
          margin-top: -12%;
        `}
      >
        <Row
          grid={1 / 2}
          gutter={1}
        >
          <Column>
            <ExtendedBox>
              <ContentBoxIcon icon={mlIcon} />
              <h2>Machine Learning Ready</h2>
              <p>
                In addition to the various types of processed data, all data is cleanly organized with standardized ticker symbols across all exchanges, allowing any ML or AI models to be prediction ready without any additional formatting needed
              </p>
            </ExtendedBox>
          </Column>
          <Column>
            <ExtendedBox>
              <ContentBoxIcon icon={backtestIcon} />
              <h2>Backtesting Ready</h2>
              <p>
                Using the same synchronized clock when we first receive it, data is time stamped for all of our available exchanges; allowing for significantly more accurate backtesting once downloaded
              </p>
            </ExtendedBox>
          </Column>
          <Column>
            <ExtendedBox>
              <ContentBoxIcon icon={redundancyIcon} />
              <h2>Redundant</h2>
              <p>
                With multiple servers collectively creating our infrastructure this eliminates data errors and creates redundancy even when outages occur
              </p>
            </ExtendedBox>
          </Column>
          <Column>
            <ExtendedBox>
              <ContentBoxIcon icon={unifiedIcon} />
              <h2>Multi-Choice Data Preference</h2>
              <p>
                All of our exchange data can be purchased in a variety of forms for your needs, this includes Tick Data, Raw Data, Order Books, OHLCV, VWAP, and TWAP
              </p>
            </ExtendedBox>
          </Column>
        </Row>
      </Container>
      <Container size="md">
        <Row
          mt="6em"
          mb="6em"
        >
          <Column>
            <SectionHeading>
              <TopTitle>Get in Touch</TopTitle>
              <h2>Not seeing what you’re looking for? If something you require is not listed, please contact us, we are happy to meet your needs.</h2>
              <Button
                as="a"
                href="mailto:sales@investabit.com"
                appearance="secondary"
                alt="Get in touch"
                target="_blank"
              >
                Contact Us
              </Button>
            </SectionHeading>
          </Column>
        </Row>
      </Container>
      <Wrapper bgColor={theme.color.grey[0]}>
        <Container>
          <Row
            mt="4em"
            mb="4em"
          >
            <Column>
              <SectionHeading>
                <TopTitle>Tick by Tick Data</TopTitle>
                <h2>Supported Exchanges</h2>
              </SectionHeading>
            </Column>
          </Row>
        </Container>
        <Exchanges />
      </Wrapper>
      <Container size="md">
        <Row
          mt="4em"
          mb="4em"
        >
          <Column>
            <SectionHeading>
              <TopTitle>Sample Data</TopTitle>
              <h2>Want to explore more? </h2>
              <p>Download some sample data to get a picture of what you will get. Please note we can fulfil most data format requests such as flat files, csv and json to name a few</p>
            </SectionHeading>
          </Column>
        </Row>
      </Container>
      <Container>
        <Row
          grid={1 / 3}
          gutter={0.5}
        >
          <Column>
            <LinkOut to="https://storage.googleapis.com/investabit-index/data-samples/bitfinex_BTCUSD_trades_sample.csv">
              <LinkOutBox bg={theme.color.primary[0]}>
                <span>Raw Trades</span>
              </LinkOutBox>
            </LinkOut>
          </Column>
          <Column>
            <LinkOut to="https://storage.googleapis.com/investabit-index/data-samples/bitfinex_BTCUSD_lob_sample.csv">
              <LinkOutBox bg={theme.color.primary[1]}>
                <span>Raw Books</span>
              </LinkOutBox>
            </LinkOut>
          </Column>
          <Column>
            <LinkOut to="https://storage.googleapis.com/investabit-index/data-samples/bitfinex_BTCUSD_ohlcv_sample.csv">
              <LinkOutBox bg={theme.color.primary[4]}>
                <span>OHLCV</span>
              </LinkOutBox>
            </LinkOut>
          </Column>
        </Row>
      </Container>
      <Container>
        <CTA
          title="Ready to Make the Move Forward?"
          paragraph="Create an account to get started or click below to look through the dataset available for download."
          button={{
            title: 'Start Your Order',
            to: '/account/register'
          }}
        />
      </Container>
      <Container size="md">
        <Row
          mt="4em"
          mb="4em"
        >
          <Column>
            <SectionHeading>
              <TopTitle>We have you covered</TopTitle>
              <h2>Looking for More?</h2>
              <p>If you are interested in access to the data via the API or real-time streaming protocols like WebSocket or FIX, then take a look at our other product offerings, available through <Link to="/products/data-api/">Investabit Data API</Link></p>
            </SectionHeading>
          </Column>
        </Row>
      </Container>
      <Wrapper
        css={`
          border-top: 1px solid ${ theme.color.grey[1] };
        `}
      >
        <Container>
          <Row
            grid={1 / 2}
            gutter={2}
          >
            <Column>
              <h2>Historical Bitcoin Data Download historical data for every exchange and cryptocurrency</h2>
              <strong
                css={`
                  color: ${ theme.color.primaryColor };
                  margin-top: 2em;
                `}
              >
                Downloadable trade history market data for the global crypto ecosystem
              </strong>
            </Column>
            <Column>
              <LiftContainer>
                <h4>2,200+ Markets Available</h4>
                <h4>Across 46+ Cryptocurrency Exchanges</h4>
                <ul>
                  <li><Tick /> Raw tick data</li>
                  <li><Tick /> OHLCV</li>
                  <li><Tick /> Order Books</li>
                </ul>
                <p>Access a painstakingly created, complete dataset for backtesting, machine learning, analysis and charting, available through our self service platform</p>
              </LiftContainer>
            </Column>
          </Row>
          <Row
            dir="column"
            css={`
              border-top: 1px solid ${ theme.color.grey[1] };
              padding-top: 2em;
            `}
          >
            <Column>
              <InfoBox>
                <div>
                  <Info />
                </div>
                <p>Investabit provides historical market data on not only bitcoin but hundreds of alternative cryptocurrencies. Data is available in both RAW (Every Trade) and OHLCV (Open, High, Low, Close, Volume) format either as a flat file or as a tab-delimited CSV file.</p>
              </InfoBox>
            </Column>
            <Column>
              <InfoBox>
                <div>
                  <Info />
                </div>
                <p>With A La Carte self service, you choose your exchange, market, and data type, and we&apos;ll have a custom order in your inbox in less than 24 hours.</p>
              </InfoBox>
            </Column>
          </Row>
        </Container>
      </Wrapper>
      <Wrapper bgColor={theme.color.grey[0]}>
        <Container id="pricing">
          <Row
            mt="4em"
            mb="2em"
          >
            <Column>
              <SectionHeading>
                <TopTitle>Different tiers for different needs</TopTitle>
                <h2>Pricing</h2>
              </SectionHeading>
            </Column>
          </Row>
        </Container>
        <Pricing data={DataPricing} />
      </Wrapper>
    </>
  )
}

ChatBubble.propTypes = {
  options: PropTypes.shape({
    size: PropTypes.oneOf(['small', 'big']).isRequired,
    corner: PropTypes.string.isRequired,
    bgColor: PropTypes.string.isRequired,
    position: PropTypes.object
  })
}

export default DataSales
